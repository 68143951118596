<template>
  <div>
    <praeco-form-item label="Integration URL" prop="pagertreeIntegrationUrl">
      <el-input id="pagertreeIntegrationUrl" v-model="pagertreeIntegrationUrl" :disabled="viewOnly" />
      <label>URL generated by PagerTree for the integration.</label>
    </praeco-form-item>

    <praeco-form-item label="Proxy" prop="pagertreeProxy" required>
      <el-input id="pagertreeProxy" v-model="pagertreeProxy" :disabled="viewOnly" />
      <label>
        By default ElastAlert 2 will not use a network proxy to send notifications to PagerTree.
        Set this option using hostname:port if you need to use a proxy.
      </label>
    </praeco-form-item>
  </div>
</template>

<script>
export default {
  props: ['viewOnly'],

  computed: {
    pagertreeIntegrationUrl: {
      get() {
        return this.$store.state.config.alert.pagertreeIntegrationUrl;
      },
      set(value) {
        this.$store.commit(
          'config/alert/UPDATE_PAGERTREE_INTEGRATION_URL',
          value
        );
      }
    },

    pagertreeProxy: {
      get() {
        return this.$store.state.config.alert.pagertreeProxy;
      },
      set(value) {
        this.$store.commit(
          'config/alert/UPDATE_PAGERTREE_PAGERTREE_PROXY',
          value
        );
      }
    }
  },

  methods: {
  }
};
</script>

<style lang="scss">
</style>
