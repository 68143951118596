var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{attrs:{"gutter":19}},[_c('el-col',{attrs:{"span":_vm.useTimeWindow ? 6 : 19}},[_c('el-form-item',{attrs:{"label":"Use Time Window"}},[_c('el-switch',{attrs:{"disabled":_vm.viewOnly},on:{"change":_vm.changeTimeWindow},model:{value:(_vm.useTimeWindow),callback:function ($$v) {_vm.useTimeWindow=$$v},expression:"useTimeWindow"}}),_c('label',[_vm._v(" Use time window in order to send alerts only at specific time range. ")])],1)],1),(_vm.useTimeWindow)?_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Start time","prop":"timeWindowStartTime","required":""}},[_c('el-time-select',{attrs:{"disabled":_vm.viewOnly,"picker-options":{
          start: '00:00',
          step: '00:15',
          end: '23:59'
        },"placeholder":"Select time"},model:{value:(_vm.timeWindowStartTime),callback:function ($$v) {_vm.timeWindowStartTime=$$v},expression:"timeWindowStartTime"}}),(!_vm.viewOnly)?_c('label',[_vm._v("Start time of the range.")]):_vm._e()],1)],1):_vm._e(),(_vm.useTimeWindow)?_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"End time","prop":"timeWindowEndTime","required":""}},[_c('el-time-select',{attrs:{"disabled":_vm.viewOnly,"picker-options":{
          start: '00:00',
          step: '00:15',
          end: '23:59'
        },"placeholder":"Select time"},model:{value:(_vm.timeWindowEndTime),callback:function ($$v) {_vm.timeWindowEndTime=$$v},expression:"timeWindowEndTime"}}),(!_vm.viewOnly)?_c('label',[_vm._v("Start time of the range.")]):_vm._e()],1)],1):_vm._e(),(_vm.useTimeWindow)?_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"Drop if","prop":"timeWindowDropIf","required":""}},[_c('el-select',{staticClass:"el-select-wide",attrs:{"disabled":_vm.viewOnly,"placeholder":""},model:{value:(_vm.timeWindowDropIf),callback:function ($$v) {_vm.timeWindowDropIf=$$v},expression:"timeWindowDropIf"}},[_c('el-option',{key:"outside",attrs:{"label":"Outside","value":"outside"}}),_c('el-option',{key:"inside",attrs:{"label":"Inside","value":"inside"}})],1),(!_vm.viewOnly)?_c('label',[_vm._v("Drop if inside/outside given range.")]):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }